<ng-container *ngIf="field.type === 'text' || field.type === 'hiddenInput' || field.type === 'phone' || field.type === 'email' || (field.type ===
'flightNumber' && formGroup.controls[field.property]?.value && formGroup.controls[field.property]?.value !== '')">
  {{formGroup.controls[field.property]?.value}}
</ng-container>

<ng-container *ngIf="field.type === 'textArea'">
  {{formGroup.controls[field.property]?.value}}
</ng-container>

<ng-container *ngIf="field.type === 'dateTime'">
    {{this.displayTime}} {{this.timeZoneOffset}}
</ng-container>

<ng-container *ngIf="field.type === 'address' || field.type === 'hiddenInputAddress'">
  {{(formGroup.controls[field.property]?.value && formGroup.controls[field.property]?.value.formatted_address ?
  formGroup.controls[field.property]?.value.formatted_address :
  formGroup.controls[field.property]?.value)}}
</ng-container>

<ng-container *ngIf="field.type === 'number' && formGroup.controls[field.property]?.value !== 0">
  {{formGroup.controls[field.property]?.value}}
</ng-container>
<ng-container *ngIf="field.type === 'sortable-address' && getArray().length > 0">
    <ul>
      <li *ngFor="let stopOver of getArray(); index as fi;">
        {{(stopOver.value && stopOver.value.formatted_address ?
        stopOver.value.formatted_address :
        stopOver.value)}}
      </li>
    </ul>
</ng-container>

<ng-container *ngIf="field.type === 'checkbox'">
  {{(formGroup.controls[field.property]?.value ? 'yes' : 'no') | translate | ucFirst}}
</ng-container>

<ng-container *ngIf="field.type === 'select'">
  <ng-container *ngFor="let value of field.values">
    <ng-container *ngIf="isSelectValue(value.value, formGroup.controls[field.property]?.value)">
      {{value.label | translate | ucFirst | unicorn:value.value}}
    </ng-container>
  </ng-container>
</ng-container>

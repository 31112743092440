import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {StateService} from './state.service';
import {environment} from "../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'any'
})
export class FormResolverService implements Resolve<any> {

  constructor(private http: HttpClient,
              private state: StateService,
              private translate: TranslateService,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    if (route.paramMap.get('language') || (route.parent && route.parent.paramMap)) {
      this.state.language = route.paramMap.get('language') ? route.paramMap.get('language') : route.parent.paramMap.get('language');
    }
    if(!this.state.language) {
      this.state.language = this.translate.getBrowserLang();
    }
    let formId = route.paramMap.get('formId') ? route.paramMap.get('formId') : route.parent.paramMap.get('formId');

    const checkForHexRegExp = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i

    if (checkForHexRegExp.test(formId) || environment.customDomains[window.location.hostname]) {
      if (environment.customDomains[window.location.hostname]) {
        formId = environment.customDomains[window.location.hostname];
      }
      if (!formId) {
        console.log('Missing enviroment hostname:', window.location.hostname);
      }
      const api = new ApiService(this.http, this.state, 'forms', formId);
      return api.getCustom(`/forms/${formId}`).pipe(
        take(1),
        mergeMap(form => {
          this.state.currency = form.config.currency;
          this.state.formId = form.id;
          this.state.timeZone = form.config.timeZone;
          if (form.config.styles) {
            this.state.formStyles = form.config.styles;
          }
          if (form) {
            return api.getCustom(`/forms/companySettings`).pipe(
              take(1),
              mergeMap((settingsResult: any) => {
                this.state.companySettings = settingsResult.settings;
                return api.getCustom(`/forms/validateCloudConnectContract`).pipe(
                  take(1),
                  mergeMap((result) => {
                    if (result.status === 'not_active') {
                      this.router.navigate([`/no-contract`]);
                    } else if (form || true) {
                      this.state.realm = result.realm;
                      this.state.googleApiKey = result.googleKey;
                      return of(form);
                    } else {
                      this.router.navigate([`/`]);
                      return EMPTY;
                    }
                  })
                );
              })
            );
          } else {
            console.log('xxx');
            this.router.navigate([`/`]);
            return EMPTY;
          }
        })
      );
    } else {
      const where = {
        'where': {
          'config.customUrl': formId,
        }
      };
      const api = new ApiService(this.http, this.state, 'forms', formId);
      return api.getCustom(`/forms/`, where).pipe(
        take(1),
        mergeMap((form) => {
          form = form[0];
          if (form) {
            this.state.currency = form.config.currency;
            this.state.formId = form.id;
            this.state.timeZone = form.config.timeZone;
            if (form.config.styles) {
              this.state.formStyles = form.config.styles;
            }

            const api = new ApiService(this.http, this.state, 'forms', form.id);
            return api.getCustom(`/forms/companySettings`).pipe(
              take(1),
              mergeMap((settingsResult: any) => {
                this.state.companySettings = settingsResult.settings;
                return api.getCustom(`/forms/validateCloudConnectContract`).pipe(
                  take(1),
                  mergeMap((result) => {
                    if (result.status === 'not_active') {
                      this.router.navigate([`/${this.state.language}/no-contract`]);
                    } else if (form || true) {
                      this.state.realm = result.realm;
                      this.state.googleApiKey = result.googleKey;
                      return of(form);
                    } else {
                      this.router.navigate([`/`]);
                      return EMPTY;
                    }
                  })
                );
              })
            );
          } else {
            this.router.navigate([`/`]);
            return EMPTY;
          }
        })
      );
    }
  }

}

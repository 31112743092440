import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FlatpickrOptions} from 'ng2-flatpickr';
import {Field} from '../../interfaces/field';
import moment from 'moment-timezone';
import {Subscription} from 'rxjs';
import {faCalendar, faCheck, faClock, faInfoCircle, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons';
import {FieldErrorService} from '../../services/field-error.service';
import {StateService} from '../../services/state.service';
import {Moment} from 'moment';
import {faPlaneArrival} from "@fortawesome/free-solid-svg-icons/faPlaneArrival";

let isArray = function (a) {
  return (!!a) && (a.constructor === Array);
};

@Component({
  selector: 'app-date-time-field',
  templateUrl: './date-time-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./date-time-field.component.scss']
})
export class DateTimeFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('picker', {static: true}) element;
  @ViewChild('desktopTimePicker', {static: true}) element2;
  // @ViewChild('datePicker', { static: true }) datePicker;
  @ViewChild('timePicker', {static: true}) timePicker;
  @Input() config: Field;
  @Input() airportDates: string;
  @Input() control: UntypedFormGroup;
  @Input() summary = false;
  @Input() orderMode: string;
  @Input() configRequestedDestinationDateOffset: number;
  @Input() airportDeparture: boolean;
  @Input() airportDestination: boolean;
  @Input() minimumOrderTime = 60;
  @Input() orderTimesHandler = 'availability';
  @Output() doToggleHelpText: EventEmitter<any> = new EventEmitter<any>();
  isReturnTrip = false;
  options: FlatpickrOptions = {
    weekNumbers: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
    allowInput: false,
    wrap: true,
    onClose: this.onClose.bind(this),
    onOpen: this.onOpen.bind(this),
    onChange: this.onChangeTime.bind(this),
    defaultDate: new Date(),
    locale: {
      firstDayOfWeek: 1
    },
    minDate: new Date(),
    maxDate: new Date(),
    utc: true
  };
  options2: FlatpickrOptions = {
    'weekNumbers': true,
    'enableTime': false,
    'time_24hr': true,
    'minuteIncrement': 1,
    'allowInput': false,
    'wrap': true,
    onClose: this.onClose.bind(this),
    onOpen: this.onOpen.bind(this),
    onChange: this.onChange.bind(this),
    defaultDate: new Date(),
    locale: {
      firstDayOfWeek: 1
    },
    minDate: new Date(),
    dateFormat: 'd-m-Y',
    utc: false
  };
  timeDisplay = 'H';
  mode = 'requestedDate';
  opened = false;
  forcedDate: string;
  requestedDateChanges: Subscription;
  airportDestinationChanges: Subscription;
  minDate: string;
  hasErrors = false;

  mobileMinDateString = '';
  mobileDateString = '';
  mobileMinTimeString = '';
  mobileTimeString = '';

  selectedDate = '';
  selectedTime = '';
  selectedClock = '';

  faCheck = faCheck;
  faClock = faClock;
  faCalendar = faCalendar;
  faInfoCircle = faInfoCircle;

  days = ["sun", "mon", "thu", "wed", "thr", "fri", "sat"];
  allowedDays = [];
  timeHourOptions = [];
  timeMinutesOptions = [];
  clockOptions = ['AM', 'PM'];
  minTime = '00:00';
  maxTime = '23:55';
  fullyDisabledDays = [];
  hasOptions = true;
  fieldTitle = 'requested_date_field_label';
  minRequestedDate: any;
  protected readonly faPlaneArrival = faPlaneArrival;
  protected readonly faPlaneDeparture = faPlaneDeparture;
  protected readonly Math = Math;

  constructor(private errorService: FieldErrorService,
              public _state: StateService) {
    if (this._state.companySettings.dateFormat) {
      if (this._state.companySettings.dateFormat === 'MMM Do YYYY') {
        this.options2.dateFormat = 'M J Y';
      }
    }
  }

  setTimeSelection() {
    // console.group('DateLimits:', this.config.property)
    console.groupCollapsed('DateLimits:', this.config.property)
    // console.log('Clock:', this._state.companySettings.clock);
    // console.log('minTime', this.minTime);
    console.log('orderTimesHandler', this.orderTimesHandler);

    this.timeHourOptions = [];
    this.timeMinutesOptions = [];
    const minSplit = [];
    let minSplitString = this.minTime.split(':');
    const calculatedMinTime = moment(`${this.selectedDate}T${this.minTime}`).format('HH:mm');
    if ((this.orderTimesHandler === 'always' && !this.firstAllowedDaySelected()) ||
      (this.orderTimesHandler === 'always_forward_time' && moment(this.minDate).format('YYYY-MM-DD') !== this.selectedDate)
    ) {
      /**
       * Check if date is tomorrow
       */
      if (this.orderTimesHandler === 'always_forward_time' && moment(this.selectedDate).format('YYYY-MM-DD') === moment().add(1, 'days').format('YYYY-MM-DD') && moment(this.minDate).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
        minSplitString = calculatedMinTime.split(':');
        minSplit[0] = parseInt(minSplitString[0]);
        minSplit[1] = parseInt(minSplitString[1]);
      } else {
        minSplit[0] = 0;
        minSplit[1] = 0;
      }
    } else if (this.orderTimesHandler === 'always_forward_time' && this.firstAllowedDaySelected()) {
      let minSplitStringTest = calculatedMinTime.split(':');

      if (minSplitStringTest[0] <= this.minTime.split(':')[0] || (minSplitStringTest[0] === this.minTime.split(':')[0] && minSplitStringTest[1] <= this.minTime.split(':')[1])) {
        this.selectedTime = moment(`${this.selectedDate}T${calculatedMinTime}:00`).format('' + this.timeDisplay + '' + this.timeDisplay + ':mm');
        minSplit[0] = parseInt(minSplitStringTest[0]);
        minSplit[1] = parseInt(minSplitStringTest[1]);
      } else {
        minSplit[0] = parseInt(minSplitString[0]);
        minSplit[1] = parseInt(minSplitString[1]);
      }
    } else {
      minSplit[0] = parseInt(minSplitString[0]);
      minSplit[1] = parseInt(minSplitString[1]);
    }

    const selectedTimeSplit = this.selectedTime.split(':');

    if (this.maxTime == '00:00') {
      this.maxTime = '24:00';
    }
    // console.log('maxTime', this.maxTime);

    if (this._state.companySettings.clock === '12') {
      if (!this.selectedClock && minSplit[0] < 12) {
        this.selectedClock = 'AM';
      }

      if (minSplit[0] > 12) {
        minSplit[0] = minSplit[0] - 12;
        this.clockOptions = ['PM'];
      } else {
        this.clockOptions = ['AM', 'PM'];
      }
    }
    const maxSplit = [];
    const maxSplitString = this.maxTime.split(':');
    if (this.orderTimesHandler === 'always' || this.orderTimesHandler === 'always_forward_time') {
      maxSplit[0] = 23;
      maxSplit[1] = 59;
    } else {
      maxSplit[0] = parseInt(maxSplitString[0]);
      maxSplit[1] = parseInt(maxSplitString[1]);
    }
    console.log(minSplit);
    console.log(maxSplit);
    let dateHasOverwride = false;
    const currentHour = 0;
    let x = 0;
    for (let x = 0; x < (this._state.companySettings.clock !== '12' ? 24 : 12); x++) {
      this.timeHourOptions.push({
        value: `${(x < 10 ? '0' + x : x)}`,
        selectedValue: `${(this.selectedClock === 'PM' ? (x + 12) : (x < 10 ? '0' + x : x))}`,
        enabled: (
          (
            (this._state.companySettings.clock !== '12' || this.selectedClock === 'AM' ?
                (x >= minSplit[0] && (x < maxSplit[0] || (x === maxSplit[0] && maxSplit[1] > 0))) :
                (this.selectedClock === 'PM' &&
                  (x + 12) >= minSplit[0] && ((x + 12) < maxSplit[0] || ((x + 12) === maxSplit[0] && maxSplit[1] > 0))
                )
            )
          ) &&
          (minSplit[0] !== x || minSplit[1] < 55)
        )
      });
    }

    console.log(this.timeHourOptions);

    if (minSplit[1] > 55) {
      minSplit[1] = '0';
    }

    if (maxSplit[1] == '0' && maxSplit[0] != minSplit[1]) {
      maxSplit[1] = 55;
    }

    for (let i = 0; i < 60; i = i + 5) {
      this.timeMinutesOptions.push({
        value: `${(i < 10 ? '0' + i : i)}`,
        enabled: (
          (minSplit[0] !== parseInt(selectedTimeSplit[0]) || i >= minSplit[1]) &&
          (maxSplit[0] !== parseInt(selectedTimeSplit[0]) || i <= maxSplit[1])
        )
      });
    }

    if (this._state.companySettings.availabilityOverrides && this._state.companySettings.availabilityOverrides.length > 0) {
      console.group('availabilityOverrides:')
      console.log(this._state.companySettings.availabilityOverrides)
      console.log('selectedDate:', this.selectedDate);
      console.log('selectedTime:', this.selectedTime);
      this._state.companySettings.availabilityOverrides.sort((a, b) => {
        if (a.startDate < b.startDate) {
          return -1;
        } else if (a.startDate > b.startDate) {
          return 1;
        } else {
          return 0;
        }
      });
      Object.keys(this._state.companySettings.availabilityOverrides).forEach((v, i) => {
        console.log('-------------------------');
        const start = moment(this._state.companySettings.availabilityOverrides[v].startDate).tz(this._state.timeZone).format('YYYY-MM-DDT00:00:00');

        if (this._state.companySettings.availabilityOverrides[v].endDate.indexOf('24:00:00') > -1) {
          this._state.companySettings.availabilityOverrides[v].endDate = this._state.companySettings.availabilityOverrides[v].endDate.replace('24:00:00', '23:59:59');
        }

        const end = moment(this._state.companySettings.availabilityOverrides[v].endDate).tz(this._state.timeZone).format('YYYY-MM-DDT23:59:59');

        if (moment(`${this.selectedDate}T${this.selectedTime}`).tz(this._state.timeZone).isBetween(start, end)) {
          if (dateHasOverwride) {
            console.log('Second override in an single day!: ');
          }

          dateHasOverwride = true;
          console.log('dateHasOverwride: ', dateHasOverwride);

          let overRideStartsAtSelectedDate = false;
          let overRideEndsAtSelectedDate = false;

          if (moment(`${this.selectedDate}T${this.selectedTime}`).tz(this._state.timeZone).isBetween(start, moment(this._state.companySettings.availabilityOverrides[v].startDate).tz(this._state.timeZone).format('YYYY-MM-DDT23:59:59')) && moment(this._state.companySettings.availabilityOverrides[v].endDate).tz(this._state.timeZone).format('HH:mm') !== '00:00') {
            overRideStartsAtSelectedDate = true;
          }
          if (moment(`${this.selectedDate}T${this.selectedTime}`).tz(this._state.timeZone).isBetween(moment(this._state.companySettings.availabilityOverrides[v].endDate).tz(this._state.timeZone).format('YYYY-MM-DDT00:00:00'), end)) {
            overRideEndsAtSelectedDate = true;
          }

          console.log('overRideStartsAtSelectedDate:', overRideStartsAtSelectedDate);
          console.log('overRideEndsAtSelectedDate:', overRideEndsAtSelectedDate);

          if (overRideStartsAtSelectedDate) {
            const minSplitString = moment(this._state.companySettings.availabilityOverrides[v].startDate).tz(this._state.timeZone).format('' + this.timeDisplay + '' + this.timeDisplay + ':mm').split(':');
            minSplit[0] = parseInt(minSplitString[0]);
            minSplit[1] = parseInt(minSplitString[1]);
          }
          if (overRideEndsAtSelectedDate) {
            const maxSplitString = moment(this._state.companySettings.availabilityOverrides[v].endDate).tz(this._state.timeZone).format('' + this.timeDisplay + '' + this.timeDisplay + ':mm').split(':');
            maxSplit[0] = parseInt(maxSplitString[0]);
            maxSplit[1] = parseInt(maxSplitString[1]);

            if (maxSplit[0] == '23' && maxSplit[1] == '59') {
              maxSplit[0] = 24;
              maxSplit[1] = 0;
            }
          }

          if (this._state.companySettings.availabilityOverrides[v].type === 'unavailable') {
            for (let x = minSplit[0]; x < (this._state.companySettings.clock !== '12' ? maxSplit[0] : 12); x++) {
              this.timeHourOptions[x] = {
                value: `${(x < 10 ? '0' + x : x)}`,
                selectedValue: `${(this.selectedClock === 'PM' ? (x + 12) : (x < 10 ? '0' + x : x))}`,
                enabled: (
                  (
                    (this._state.companySettings.clock !== '12' || this.selectedClock === 'AM' ?
                        (x >= maxSplit[0] && (x < minSplit[0] || (x === maxSplit[0] && minSplit[1] > 0))) :
                        (this.selectedClock === 'PM' &&
                          (x + 12) >= maxSplit[0] && ((x + 12) < minSplit[0] || ((x + 12) === minSplit[0] && minSplit[1] > 0))
                        )
                    )
                  ) &&
                  (maxSplit[0] !== x || maxSplit[1] < 55)
                )
              };
            }
          } else {
            for (let x = minSplit[0] - 1; x <= (this._state.companySettings.clock !== '12' ? maxSplit[0] : 12); x++) {
              this.timeHourOptions[x] = {
                value: `${(x < 10 ? '0' + x : x)}`,
                selectedValue: `${(this.selectedClock === 'PM' ? (x + 12) : (x < 10 ? '0' + x : x))}`,
                enabled: (
                  (
                    (this._state.companySettings.clock !== '12' || this.selectedClock === 'AM' ?
                        (x <= maxSplit[0] && (x >= minSplit[0] || (x === maxSplit[0]))) :
                        (this.selectedClock === 'PM' &&
                          (x + 12) <= maxSplit[0] && ((x + 12) > minSplit[0] || ((x + 12) === minSplit[0] && minSplit[1] > 0))
                        )
                    )
                  )
                )
              };
            }
          }


          let cnt = 0;
          for (let i = 0; i < 60; i = i + 5) {
            this.timeMinutesOptions[cnt] = {
              value: `${(i < 10 ? '0' + i : i)}`,
              enabled: (
                (minSplit[0] !== parseInt(selectedTimeSplit[0]) || i >= minSplit[1]) &&
                (maxSplit[0] !== parseInt(selectedTimeSplit[0]) || i <= maxSplit[1])
              )
            };
            cnt++;
          }
        }
      });
      console.groupEnd();
    }

    if (!this.selectedClock && this._state.companySettings.clock === '12') {
      this.selectedClock = 'AM';
    }

    console.groupEnd();
  }

  ngOnInit() {
    // this.minDate = this.getMinRequestedDate();
    // if (this.config.property === 'returnRequestedDate') {
    //   this.isReturnTrip = true;
    // }
    this.mode = this.config.property;
    // console.log(`${this.mode} - ${this.control.controls[this.mode].value}`);
    this.setDropDownOption();
  }

  setDropDownOption() {
    let optionCnt = 0;
    if (this.airportDates !== 'none') {
      if (this.orderMode !== 'airport') {
        optionCnt++;
      } else if (this.airportDates === 'optional') {
        optionCnt++;
      }
      if ((this.orderMode === 'airport' && this._state.airportDestination) && !this._state.airportDeparture) {
        optionCnt++;
      }
      if (this.orderMode === 'airport' && this._state.airportDeparture) {
        optionCnt++;
      }
    }
    if (optionCnt > 1) {
      this.hasOptions = true;
    } else {
      this.hasOptions = false;
    }
  }

  ngAfterViewInit(): void {
    this.ngOnInit();
    const self = this;
    let dateClientTZ;
    let date, minDate, splitDate;
    // console.log('ngAfterViewInit', this.mode);

    this.minDate = this.getMinRequestedDate();
    dateClientTZ = this.minDate.toString();
    splitDate = dateClientTZ.split('T');

    if (splitDate[0] !== moment().format('YYYY-MM-DD')) {
      minDate = moment(`${splitDate[0]}T${splitDate[1].substr(0, 8)}`).tz(this._state.timeZone).toDate();
    } else {
      minDate = moment(`${splitDate[0]}T${splitDate[1].substr(0, 8)}`).tz(this._state.timeZone).add('5', 'minutes').toDate();
    }

    if (this.control.controls[this.mode].value && this._state.companySettings.clock === '12') {
      this.selectedClock = moment(this.control.controls[this.mode].value).tz(this._state.timeZone).format('A');
    }

    if (!this.control.controls[this.mode].value ||
      moment(this.control.controls[this.mode].value).tz(this._state.timeZone).isBefore(moment(minDate).tz(this._state.timeZone)) || this.control.controls[this.mode].value === 'Invalid date') {
      date = minDate;
    } else {
      dateClientTZ = this.control.controls[this.mode].value.toString();
      splitDate = dateClientTZ.split('T');
      date = moment(`${splitDate[0]}T${splitDate[1].substr(0, 8)}`).tz(this._state.timeZone).toDate();
    }

    this.element.flatpickr.set('defaultDate', date);
    this.element.flatpickr.set('firstDayOfWeek', 1);
    this.element.flatpickr.set('minDate', minDate);

    if (moment(date).isBefore(moment(minDate))) {
      date = minDate;
    }
    this.element.flatpickr.setDate(date, false, 'd-m-Y ' + this.timeDisplay + ':i');
    self.allowedDays = [];
    if (this.orderTimesHandler === 'availability' && self._state.companySettings.availability && self._state.companySettings.availability.availableDays) {
      if (!self._state.companySettings.availability.availableDays[this.days[0]]) {
        let newSettings = {};
        Object.keys(self._state.companySettings.availability.availableDays).forEach((v, i) => {
          let daySplit = v.split('_');
          newSettings[daySplit[1]] = {
            active: self._state.companySettings.availability.availableDays[v],
            start: '00:00',
            end: '00:00',
          }
        });
        self._state.companySettings.availability.availableDays = newSettings;
      }
      Object.keys(self._state.companySettings.availability.availableDays).forEach((v, i) => {
        if (typeof (self._state.companySettings.availability.availableDays[v][0]) === 'object') {
          Object.keys(self._state.companySettings.availability.availableDays[v]).forEach((v2, i2) => {
            if (!self._state.companySettings.availability.availableDays[v][v2].active && !self.allowedDays.includes((i === 6 ? 0 : (i + 1)))) {
              self.allowedDays.push((i === 6 ? 0 : (i + 1)));
            }
          });
        } else if ((self._state.companySettings.availability.availableDays[v] === 'n' || (!self._state.companySettings.availability.availableDays[v] && !self._state.companySettings.availability.availableDays[v].active)) || !self._state.companySettings.availability.availableDays[v].active) {
          self.allowedDays.push((i === 6 ? 0 : (i + 1)));
        }
      });
    }

    if (self._state.companySettings.availability && self._state.companySettings.availability.availableDays) {
      if (!self._state.companySettings.availability.availableDays[this.days[0]]) {
        let newSettings = {};
        Object.keys(self._state.companySettings.availability.availableDays).forEach((v, i) => {
          let daySplit = v.split('_');
          newSettings[daySplit[1]] = {
            active: self._state.companySettings.availability.availableDays[v],
            start: '00:00',
            end: '00:00',
          }
        });
        self._state.companySettings.availability.availableDays = newSettings;
      }
      Object.keys(self._state.companySettings.availability.availableDays).forEach((v, i) => {
        if (typeof (self._state.companySettings.availability.availableDays[v][0]) === 'object') {
          Object.keys(self._state.companySettings.availability.availableDays[v]).forEach((v2, i2) => {
            if (!self._state.companySettings.availability.availableDays[v][v2].active && !self.allowedDays.includes((i === 6 ? 0 : (i + 1)))) {
              self.allowedDays.push((i === 6 ? 0 : (i + 1)));
            }
          });
        } else if ((self._state.companySettings.availability.availableDays[v] === 'n' || (!self._state.companySettings.availability.availableDays[v] && !self._state.companySettings.availability.availableDays[v].active)) || !self._state.companySettings.availability.availableDays[v].active) {
          self.allowedDays.push((i === 6 ? 0 : (i + 1)));
        }
      });
    }
    self.fullyDisabledDays = [];
    if (this.orderTimesHandler === 'availability' && self._state.companySettings.availabilityOverrides) {
      Object.keys(self._state.companySettings.availabilityOverrides).forEach((v, i) => {
        let sDate = moment(this._state.companySettings.availabilityOverrides[v].startDate);
        let eDate = moment(this._state.companySettings.availabilityOverrides[v].endDate);
        const dayDiff = eDate.diff(sDate, 'days');
        if (dayDiff > 1) {
          for (let x = 1; x < dayDiff; x++) {
            self.fullyDisabledDays.push(sDate.add(1, 'days').format('YYYY-MM-DD'));
          }
        } else if (moment(sDate).format('HH:mm') && dayDiff == 1) {
          self.fullyDisabledDays.push(sDate.format('YYYY-MM-DD'));
        }
      });
      // console.log('fullyDisabledDays:', self.fullyDisabledDays);
    }
    if (self._state.companySettings.availabilityOverrides) {
      Object.keys(self._state.companySettings.availabilityOverrides).forEach((v, i) => {
        let sDate = moment(this._state.companySettings.availabilityOverrides[v].startDate);
        let eDate = moment(this._state.companySettings.availabilityOverrides[v].endDate);
        const dayDiff = eDate.diff(sDate, 'days');
        if (dayDiff > 1) {
          for (let x = 1; x < dayDiff; x++) {
            self.fullyDisabledDays.push(sDate.add(1, 'days').format('YYYY-MM-DD'));
          }
        } else if (moment(sDate).format('HH:mm') && dayDiff == 1) {
          self.fullyDisabledDays.push(sDate.format('YYYY-MM-DD'));
        }
      });
    }

    this.element.flatpickr.set('disable', [function (date) {
      /**
       * is today allowed?
       */
      if (moment(date).isAfter(self.minDate) && moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        return true;
      }
      return (self.allowedDays.includes(date.getDay()) || self.fullyDisabledDays.includes(moment(date).format('YYYY-MM-DD')));
    }]);

    this.selectedDate = moment(date).format('YYYY-MM-DD');
    this.selectedTime = moment(date).format('' + this.timeDisplay + '' + this.timeDisplay + ':mm');
    this.onChange([date]);
  }

  convertDateToCompanyTimeZone(date: string): Moment {
    // console.log(`###############################`);
    if (typeof (this._state.timeZone) === 'undefined') {
      console.log(this._state);
    }
    let offSet, userDate;
    if (`${moment(date).format('Z')}` !== `${moment(date).tz(this._state.timeZone).format('Z')}`) {
      offSet = (moment(date).utcOffset() - moment(date).tz(this._state.timeZone).utcOffset()) / 60;
      userDate = moment(date).tz(this._state.timeZone).add(offSet, 'hours');
    } else {
      userDate = moment(date).tz(this._state.timeZone);
    }
    // console.log(`userDate: ${moment(userDate).toDate()}`);
    // console.log(`userDate: ${moment(userDate).tz()}`);
    // console.log(`userDate: ${moment(userDate).format()}`);
    return moment(userDate);
  }

  getMinRequestedDate(): string {
    console.groupCollapsed('getMinRequestedDate:', this.config.property);
    console.log(this._state.timeZone);
    const self = this;
    let newDate, currentDate = moment();

    if (!self._state.companySettings.availability.availableDays) {
      this.orderTimesHandler = 'always';
    }

    if (this.config.property === 'returnRequestedDate') {
      newDate = moment().tz(this._state.timeZone).add(moment(this.control.controls['requestedDate'].value).diff(moment().tz(this._state.timeZone), 'minutes'), 'minutes');
    } else {
      newDate = moment().tz(this._state.timeZone).add(this.minimumOrderTime, 'minutes');
    }
    console.log(newDate.format());

    /**
     * Are we before the first timeslot?
     * Or are we closed today?
     */

    /**
     * Create moment object for today at start using
     * self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][0].start
     */

    if(self._state.companySettings && self._state.companySettings.availability &&
      self._state.companySettings.availability.availableDays &&
      self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]] &&
      self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][0] &&
      self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][0].start) {
      let splitStart = self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][0].start.split(':');
      console.log('OrderTimesHandler:', this.orderTimesHandler);
      if (this.orderTimesHandler !== 'always' &&
         (self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][0].active &&
          currentDate.isBefore(moment().hour(splitStart[0]).minute(splitStart[1])) || !self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][0].active)) {
        /**
         * Are we before the fist timeslot?
         */
        newDate = self.findFirstAvailableStartOfTimeslot(currentDate);
      } else if (self._state.companySettings && self._state.companySettings.availability && self._state.companySettings.availability.availableDays && self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]] && this.orderTimesHandler !== 'always') {
        const lastdaySlot = self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]].length - 1;

        if (this.orderTimesHandler !== 'always' &&
          self._state.companySettings && self._state.companySettings.availability &&
          self._state.companySettings.availability.availableDays &&
          self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]] &&
          self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][lastdaySlot] &&
          self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][lastdaySlot].end) {
          if (self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][lastdaySlot].end === '00:00' || self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][lastdaySlot].end === '24:00') {
            self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][lastdaySlot].end = '23:59';
          }

          let lastTimeToday = moment(`${currentDate.format('YYYY-MM-DD')}T${self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][lastdaySlot].end}:00`).tz(this._state.timeZone);

          if (!self._state.companySettings.availability.availableDays[self.days[currentDate.weekday()]][lastdaySlot].active) {
            lastTimeToday = moment(`${currentDate.format('YYYY-MM-DD')}T00:00`).tz(this._state.timeZone);
          }


          /**
           * Is the end before now? then we move to the next date to determine the newDate
           */
          if (lastTimeToday.isBefore(currentDate)) {
            newDate = self.findFirstAvailableStartOfTimeslot(currentDate);
          } else {
            console.log(newDate.format());
          }
          let date = moment(newDate).tz(this._state.timeZone).format('YYYY-MM-DD');
          let minTime = moment(`${date}T${self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][lastdaySlot].end}:00`).tz(this._state.timeZone, true);

          const checkDate = (this.orderTimesHandler === 'availability' ? newDate : currentDate);
          if (minTime.isBefore(checkDate) || !self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][lastdaySlot].active) {
            for (let x = 1; x++; x < 8) {
              newDate = newDate.add(1, 'days');
              if (self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][0].active) {
                break;
              }
            }

            newDate = moment(`${newDate.format('YYYY-MM-DD')}T${self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][0].start}:00`).tz(this._state.timeZone);

            if (this.orderTimesHandler === 'always_forward_time') {
              newDate = newDate.add(this.minimumOrderTime, 'minutes');
            }
          }
        }
      }
    }

    /**
     * Check if minTime is within a closed window, update the minOrdertime and run this function again
     */

    if (this._state.companySettings.availabilityOverrides && this._state.companySettings.availabilityOverrides.length > 0 && this.minimumOrderTime < 2000) {
      Object.keys(this._state.companySettings.availabilityOverrides).forEach((v, i) => {
        let start = moment(this._state.companySettings.availabilityOverrides[v].startDate).tz(this._state.timeZone);
        let end = moment(this._state.companySettings.availabilityOverrides[v].endDate).tz(this._state.timeZone);

        if (newDate.tz(this._state.timeZone).isBetween(start, end)) {
          const secondsToEnd = moment().tz(this._state.timeZone).diff(end) / 1000;
          this.minimumOrderTime = Math.round((0 - secondsToEnd) / 60);
          newDate = moment(this.getMinRequestedDate()).tz(this._state.timeZone);
        }
      });
      const selectedDate = newDate.format('YYYY-MM-DD');
      const selectedTime = newDate.format('' + this.timeDisplay + '' + this.timeDisplay + ':mm');

      console.log(newDate.format());
      console.log(`${selectedDate}T${selectedTime}`);
      console.log(this.convertDateToCompanyTimeZone(`${selectedDate}T${selectedTime}`).format());
      console.groupEnd();
      return this.convertDateToCompanyTimeZone(`${selectedDate}T${selectedTime}`).format();
    } else {
      console.log(newDate.format());

      const d = moment(newDate).tz(this._state.timeZone).toDate();
      let ms = 1000 * 60 * 5; // convert minutes to ms
      let roundedDate = moment(Math.ceil(d.getTime() / ms) * ms).tz(this._state.timeZone);
      newDate = roundedDate;

      const selectedDate = newDate.format('YYYY-MM-DD');
      const selectedTime = newDate.format('' + this.timeDisplay + '' + this.timeDisplay + ':mm');

      console.log(newDate.format());
      console.log(`${selectedDate}T${selectedTime}`);
      console.log(this.convertDateToCompanyTimeZone(`${selectedDate}T${selectedTime}`).format());
      console.groupEnd();
      return this.convertDateToCompanyTimeZone(`${selectedDate}T${selectedTime}`).format();
    }
  }

  findFirstAvailableStartOfTimeslot(currentDate) {
    const self = this;
    let itt = 0;
    let checkDate = moment(currentDate).tz(self._state.timeZone);
    let newDate = moment(`${checkDate.format('YYYY-MM-DD')}T${self._state.companySettings.availability.availableDays[self.days[checkDate.weekday()]][0].start}:00`).tz(self._state.timeZone).add(self.minimumOrderTime, 'minutes');

    let lastdaySlot = self._state.companySettings.availability.availableDays[self.days[checkDate.weekday()]].length - 1;
    let lastTimeToday = moment(`${checkDate.format('YYYY-MM-DD')}T${self._state.companySettings.availability.availableDays[self.days[checkDate.weekday()]][lastdaySlot].end}:00`).tz(self._state.timeZone);

    while (lastTimeToday.isBefore(newDate) && itt < 7) {
      checkDate = checkDate.add(1, 'days');

      const lastdaySlot = self._state.companySettings.availability.availableDays[self.days[checkDate.weekday()]].length - 1;

      const firstDaySlot = 0
      lastTimeToday = moment(`${checkDate.format('YYYY-MM-DD')}T${self._state.companySettings.availability.availableDays[self.days[checkDate.weekday()]][lastdaySlot].end}:00`).tz(self._state.timeZone);

      if (!self._state.companySettings.availability.availableDays[self.days[checkDate.weekday()]][lastdaySlot].active) {
        lastTimeToday = moment(`${checkDate.format('YYYY-MM-DD')}T00:00`).tz(self._state.timeZone);
      }

      newDate = moment(`${checkDate.format('YYYY-MM-DD')}T${self._state.companySettings.availability.availableDays[self.days[checkDate.weekday()]][firstDaySlot].start}:00`).tz(self._state.timeZone).add(self.minimumOrderTime, 'minutes');
      itt = itt + 1;
    }
    console.log(newDate);
    return newDate;
  }

  onChange(date: any) {
    const self = this;
    console.groupCollapsed(`onChange ${this.mode}`, date);
    const newDate = moment(date[0]);
    this.selectedDate = newDate.format('YYYY-MM-DD');
    const patch = {};

    const submitDate = this.convertDateToCompanyTimeZone(`${this.selectedDate}T${this.selectedTime}`);

    // if (moment().tz(this._state.timeZone).add(this.minimumOrderTime, 'minutes').isAfter(moment(submitDate))) {
    //   this.minDate = this.getMinRequestedDate();
    // }

    patch[this.mode] = submitDate.format();
    console.log(`onChange Result: ${this.mode} - ${patch[this.mode]}`);
    this.control.patchValue(patch);

    let minTime;
    if (self._state.companySettings && self._state.companySettings.availability && self._state.companySettings.availability.availableDays && self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]] && this.orderTimesHandler === 'availability') {
      /**
       * Update availability settings to new format?
       */
      if (!isArray(self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]])) {
        Object.keys(self._state.companySettings.availability.availableDays).forEach((day) => {
          const dayValue = self._state.companySettings.availability.availableDays[day];
          self._state.companySettings.availability.availableDays[day] = [dayValue];
        })
      }

      self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]].forEach((interval) => {
        if (interval.end === '00:00' || interval.end === '24:00') {
          interval.end = '23:59';
        }
      });

      const firstInterval = 0;
      const lastInterval = self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]].length - 1;

      minTime = moment(`${this.selectedDate}T${self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][firstInterval].start}:00`);
      let maxTime = moment(`${this.selectedDate}T${self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]][firstInterval].end}:00`).tz(this._state.timeZone, true);

      if (maxTime.isBefore(moment(this.minDate).tz(this._state.timeZone))) {
        maxTime = moment(this.minDate).tz(this._state.timeZone);
      }
      this.maxTime = maxTime.format('' + this.timeDisplay + '' + this.timeDisplay + ':mm');

      /**
       * Do we have more then 1 interval? We setup the extra intervals as overrides
       */
      if (self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]].length > 0) {
        self._state.companySettings.availability.availableDays[self.days[newDate.weekday()]].forEach((interval, i) => {
          if (i > 0) {
            this._state.companySettings.availabilityOverrides.push({
              startDate: moment(`${newDate.format('YYYY-MM-DD')}T${interval.start}:00`).toISOString(),
              endDate: moment(`${newDate.format('YYYY-MM-DD')}T${interval.end}:00`).toISOString(),
              type: 'available',
              name: 'interval'
            });
          }
        });
      }
    } else {
      this.minTime = '00:00';
      minTime = moment(`${this.selectedDate}T00:00:00`).tz(this._state.timeZone, true);

      this.maxTime = '23:59';
    }

    if (minTime.isBefore(moment(this.minDate).tz(this._state.timeZone))) {
      minTime = moment(this.minDate).tz(this._state.timeZone);
    }

    this.minTime = minTime.format('' + this.timeDisplay + '' + this.timeDisplay + ':mm');
console.log(this.maxTime);
    if (moment(`${this.selectedDate}T${this.selectedTime}`).tz(this._state.timeZone).isBefore(moment(`${this.selectedDate}T${this.minTime}`).tz(this._state.timeZone).toDate()) || moment(`${this.selectedDate}T${this.selectedTime}`).tz(this._state.timeZone).isAfter(moment(`${this.selectedDate}T${this.maxTime}`).tz(this._state.timeZone).toDate())) {
      this.selectedTime = minTime.format('' + this.timeDisplay + '' + this.timeDisplay + ':mm');
    }
    console.groupEnd();
    this.setTimeSelection();
  }

  onChangeTime($event) {
    console.groupCollapsed(`## onChangeTime ${this.mode}`);
    console.log($event.target.name);
    console.log($event.target.value);

    let selectedTimeSplit = this.selectedTime.split(':');
    if ($event.target.name === 'hours') {
      this.selectedTime = `${$event.target.value}:${selectedTimeSplit[1]}:00`;
    } else if ($event.target.name === 'minutes') {
      this.selectedTime = `${selectedTimeSplit[0]}:${$event.target.value}:00`;
    } else if ($event.target.name === 'clock') {
      this.selectedClock = $event.target.value;
    }
    selectedTimeSplit = this.selectedTime.split(':');
    console.log('selectedClock:', this.selectedClock);
    console.log('selectedTime:', this.selectedTime);

    if (this.selectedClock === 'PM') {
      selectedTimeSplit[0] = '' + (parseInt(selectedTimeSplit[0]) + 12);
      this.selectedTime = `${selectedTimeSplit[0]}:${selectedTimeSplit[1]}:00`;
    } else if (this.selectedClock === 'AM') {
      if (parseInt(selectedTimeSplit[0]) > 12) {
        selectedTimeSplit[0] = '' + (parseInt(selectedTimeSplit[0]) - 12);
      }
      this.selectedTime = `${(parseInt(selectedTimeSplit[0]) < 10 ? '0' + parseInt(selectedTimeSplit[0]) : selectedTimeSplit[0])}:${selectedTimeSplit[1]}:00`;
    }
    console.log(this.selectedDate + 'T' + this.selectedTime + '');

    let newDate = moment(this.selectedDate + 'T' + this.selectedTime + '').tz(this._state.timeZone);
    const selectedDate = newDate.format('YYYY-MM-DD');
    const patch = {};
    const submitDate = this.convertDateToCompanyTimeZone(`${selectedDate}T${this.selectedTime}`);
    patch[this.mode] = submitDate.format();
    console.log(`## onChangeTime ${this.mode}`, patch[this.mode]);

    this.control.patchValue(patch);
    console.groupEnd();

    this.setTimeSelection();
  }

  onClose() {
    this.opened = false;
  }

  onOpen() {
    this.opened = true;
  }

  changeMode(event: any) {
    console.groupCollapsed('changeMode ', this.config.property)

    const patch = {};
    let targetValue = event.target.value;
    // console.log('CurrentMode:', this.mode);

    if (this.config.property === 'returnRequestedDate') {
      targetValue = event.target.value.replace('requested', 'returnRequested');
    }
    console.log('event:', targetValue);
    if (targetValue !== this.mode) {
      patch[targetValue] = this.control.controls[this.mode].value;
      this.control.controls[targetValue].setValue(this.control.controls[this.mode].value);
      if (this.mode === 'requestedDestinationDate' || this.mode === 'returnRequestedDestinationDate') {
        this.control.controls[this.mode].setValue(null);
        patch[this.mode] = null;
        this.control.patchValue(patch);
      }
    }
    // console.log(this.control.controls[`${this.mode}Mode`].value);
    this.mode = targetValue;
    patch[this.mode] = this.control.controls[this.mode].value;
    patch['requestedDateMode'] = this.mode;
    this._state.forcedRequestedDateMode = this.mode;
    this.control.patchValue(patch);

    console.log('new mode:', this.mode);
    console.groupEnd();
  }

  setNow() {
    const patch = {};
    const now = this.getMinRequestedDate();
    this.forcedDate = now;
    patch[this.mode] = now;
    this.control.patchValue(patch);
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

  ngOnDestroy(): void {
    if (this.requestedDateChanges) {
      this.requestedDateChanges.unsubscribe();
      console.log('ngOnDestroy');
    }
  }

  getHelpText() {
    if (this.orderMode !== 'airport' || this.airportDates === 'optional') {
      return this.config.helpText;
    } else if (this.mode === 'requestedDestinationDate') {
      return 'requesteddestinationdate_date_field_help_text';
    } else if (this.mode === 'requestedScheduleDate') {
      return 'requestedscheduledate_date_field_help_text';
    } else {
      return this.config.helpText;
    }
  }

  todaySelected() {
    return (moment(this.selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));
  }

  firstAllowedDaySelected() {
    return (moment(this.selectedDate).format('YYYY-MM-DD') === moment(this.minDate).format('YYYY-MM-DD'));
  }
}

<div class="wb-googlemaps-pane">
  <div class="wb-name wb-route-name">
    {{'route' | translate | ucFirst}}
  </div>
  <span *ngIf="metrics">{{(metrics.durationDesc)}} {{'min' | translate}}, </span>
  <span *ngIf="metrics">{{metrics.distanceDesc}} {{(_state.companySettings.distanceFormat  === 'km'? 'km' : 'mi' )|translate}}</span>
  <div class="wb-googlemaps-container {{(buttonstyle==='squared'? 'wb-squared' : '')}}">
    <google-map [options]="options" [center]="this.options.center" height="300px" width="100%" *ngIf="googleMapsLoaded && this.form.config.showRouteMap && this.data.departureModel.value">
      <map-marker [options]="departureMarker" [position]="this.data.departureModel.value.gps"></map-marker>
      <span *ngIf="getStopOvers()">
        <map-marker *ngFor="let stopOver of getStopOvers() index as fi;"
                    [options]="stopOverMarker" [position]="stopOver.value && stopOver.value.gps">
        </map-marker>
      </span>
      <map-marker [options]="destinationMarker" [position]="this.data.destinationModel && this.data.destinationModel.value && this.data.destinationModel.value.gps"></map-marker>
      <map-directions-renderer *ngIf="(directionsResults | async) as directionsResults"
                               [directions]="directionsResults"
                               [options]="directionsRenderOption"></map-directions-renderer>
    </google-map>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoolLocalStorage } from '@angular-cool/storage';
import { Field } from '../interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {CouponDiscount, Direction, PaymentGateway} from '../interfaces/payment-gateway';

export interface PricingData {
  single: number;
  return: number;
  transaction: number;
  total: number;
}

@Injectable({
  providedIn: 'any'
})
export class StateService {
  private xStorageKeys: any;
  private xLanguage: string;
  private xFormId: string;
  private xFormParts: any;
  private xFormConfig: any;
  private xFormWidgetParts: any;
  private xFormGroup: UntypedFormGroup;
  private xReturnDepartureField: Field;
  private xReturnDestinationField: Field;
  private xCurrency: string;
  private xCurrencySymbol: string;
  private xCurrencies: string[];
  private xOrderPosted: boolean;
  private xRealm: string;
  private xGoogleKey: string;
  private xSettings: any;
  private xDirections: any = {};
  private xForcedRequestedDateMode: string;
  private xPois = [];
  private xPricingData: PricingData = {
    single: 0,
    return: 0,
    transaction: 0,
    total: 0,
  };
  private xPaymentGateways: PaymentGateway[] = [];
  private xTimeZone: string;

  private readonly xTranslationId = new BehaviorSubject<string>('');
  readonly translationId$ = this.xTranslationId.asObservable();

  private readonly oFormId = new BehaviorSubject<string>('');
  readonly formId$ = this.oFormId.asObservable();

  private readonly formStylesSubject = new BehaviorSubject<any>({});
  readonly formStyles$ = this.formStylesSubject.asObservable();

  private readonly orderModeSubject = new BehaviorSubject<string>('default');
  readonly orderMode$ = this.orderModeSubject.asObservable();

  private readonly airportDepartureSubject = new BehaviorSubject<boolean>(false);
  readonly airportDeparture$ = this.airportDepartureSubject.asObservable();

  private readonly airportDestinationSubject = new BehaviorSubject<boolean>(false);
  readonly airportDestination$ = this.airportDestinationSubject.asObservable();

  private readonly paymentGatewaysSubject = new BehaviorSubject<PaymentGateway[]>([]);
  readonly paymentGateways$ = this.paymentGatewaysSubject.asObservable();

  private readonly couponDiscountSubject = new BehaviorSubject<CouponDiscount>(null);
  readonly couponDiscount$ = this.couponDiscountSubject.asObservable();

  private readonly directionsSubject = new BehaviorSubject<Direction[]>(null);
  readonly directions$ = this.directionsSubject.asObservable();

  constructor(private route: ActivatedRoute,
              private vault: CoolLocalStorage) {
    this.storageKeys = {
      orderId: `${this.formId}-order-id`,
      values: `${this.formId}-values`,
      payment: `${this.formId}-payment`,
      paymentMeta: `${this.formId}-payment-meta`,
      paymentStarted: `${this.formId}-payment-started`,
    };
  }

  get storageKeys(): any {
    return this.xStorageKeys;
  }

  set storageKeys(value: any) {
    this.xStorageKeys = value;
  }

  get directions(): any {
    return this.xDirections;
  }

  set directions(value: any) {
    this.xDirections = value;
  }

  get forcedRequestedDateMode(): string {
    return this.xForcedRequestedDateMode;
  }

  set forcedRequestedDateMode(value: string) {
    this.xForcedRequestedDateMode = value;
  }

  get formId(): string {
    return this.xFormId;
  }

  set formId(value: string) {
    this.xFormId = value;
    this.oFormId.next(value);
    this.storageKeys = {
      orderId: `${value}-order-id`,
      values: `${value}-values`,
      payment: `${value}-payment`,
      paymentMeta: `${value}-payment-meta`,
      paymentStarted: `${value}-payment-started`,
    };
  }

  get language(): string {
    return this.xLanguage;
  }

  set language(value: string) {
    this.xLanguage = value;
  }

  get formParts(): any {
    return this.xFormParts;
  }

  set formParts(value: any) {
    this.xFormParts = value;
  }

  get formConfig(): any {
    return this.xFormConfig;
  }

  set formConfig(value: any) {
    this.xFormConfig = value;
  }

  get companySettings(): any {
    return this.xSettings;
  }
  set companySettings(value: any) {
    this.xSettings = value;
  }

  get formWidgetParts(): any {
    return this.xFormWidgetParts;
  }

  set formWidgetParts(value: any) {
    this.xFormWidgetParts = value;
  }

  get currency(): string {
    return this.xCurrency;
  }

  set currency(value: string) {
    this.xCurrency = value;
  }

  get currencySymbol(): string {
    return this.xCurrencySymbol;
  }

  set currencySymbol(value: string) {
    this.xCurrencySymbol = value;
  }

  get currencies(): any {
    return this.xCurrencies;
  }

  set currencies(value: any) {
    this.xCurrencies = value;
  }

  get formGroup(): UntypedFormGroup {
    return this.xFormGroup;
  }

  set formGroup(value: UntypedFormGroup) {
    this.xFormGroup = value;
  }

  get orderMode(): string {
    return this.orderModeSubject.getValue();
  }

  set orderMode(value: string) {
    this.orderModeSubject.next(value);
  }

  get airportDeparture(): boolean {
    return this.airportDepartureSubject.getValue();
  }

  set airportDeparture(value: boolean) {
    this.airportDepartureSubject.next(value);
  }

  get airportDestination(): boolean {
    return this.airportDestinationSubject.getValue();
  }

  set airportDestination(value: boolean) {
    this.airportDestinationSubject.next(value);
  }

  get returnDestinationField(): Field {
    return this.xReturnDestinationField;
  }

  set returnDestinationField(value: Field) {
    this.xReturnDestinationField = value;
  }

  get returnDepartureField(): Field {
    return this.xReturnDepartureField;
  }

  set returnDepartureField(value: Field) {
    this.xReturnDepartureField = value;
  }

  get formStyles(): any {
    return this.formStylesSubject.getValue();
  }

  set formStyles(value: any) {
    this.formStylesSubject.next(value);
  }

  get translationId() {
    return this.xTranslationId.getValue();
  }

  set translationId(value: string) {
    this.xTranslationId.next(value);
  }

  get pricingData(): PricingData {
    return this.xPricingData;
  }

  set pricingData(value: PricingData) {
    this.xPricingData = value;
  }

  get timeZone(): string {
    return this.xTimeZone;
  }

  set timeZone(value: string) {
    this.xTimeZone = value;
  }

  get realm(): string {
    return this.xRealm;
  }

  set realm(value: string) {
    this.xRealm = value;
  }

  get orderPosted(): boolean {
    return this.xOrderPosted;
  }
  set orderPosted(value: boolean) {
    this.xOrderPosted = value;
  }

  get googleApiKey(): string {
    return this.xGoogleKey;
  }

  set googleApiKey(value: string) {
    this.xGoogleKey = value;
  }

  get paymentGateways(): PaymentGateway[] {
    return this.paymentGatewaysSubject.getValue();
  }

  set paymentGateways(value: PaymentGateway[]) {
    this.paymentGatewaysSubject.next(value);
  }

  get couponDiscount(): CouponDiscount {
    return this.couponDiscountSubject.getValue();
  }

  set couponDiscount(value: CouponDiscount) {
    this.couponDiscountSubject.next(value);
  }

  get pois(): any[] {
    return this.xPois;
  }

  set pois(pois: any[]) {
    this.xPois = pois;
  }

  reset(): void {
    Object.keys(this.storageKeys).forEach(key => {
      // console.log(`StateService->reset(${this.storageKeys[key]})`);
      this.vault.removeItem(this.storageKeys[key]);
    });
  }
}

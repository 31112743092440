import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {concat, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {Address} from '../../interfaces/address';
import {Field} from '../../interfaces/field';
import {DOCUMENT} from '@angular/common';
import {FieldErrorService} from '../../services/field-error.service';
import {AIRPORTS} from '../../data/airports';
import {faMapMarker, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons';
import {ApiService} from "../../services/api.service";
import {StateService} from "../../services/state.service";
import {StorageMap} from "@ngx-pwa/local-storage";

declare let google: any;

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

@Component({
  selector: 'app-address-field',
  templateUrl: './address-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./address-field.component.scss'],
  providers: [ApiService]
})
export class AddressFieldComponent implements OnInit, AfterViewInit {
  @Input() config: Field;
  @Input() form: UntypedFormGroup;
  @Input() type: string;
  @Input() showAddStopOver: boolean;
  @Input() sortableErrors: FieldErrorService;
  @Output() openHelpModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkWorkArea: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputAutoComplete') inputAutoComplete: any;

  results: Observable<any>;
  loading = false;
  currentTerm: string;
  input = new Subject<string>();
  geocodeService: any;
  autocompleteService: any;
  autocompleteSessionToken: string;
  autocompleteSettings = {
    componentRestrictions: {}
  };
  currentLocation: {
    lat: number,
    lng: number
  } = null;
  hasLocationActive = false;
  address: Address;
  data: any[] = [];
  area: any;
  hasErrors = false;
  airports = [];
  foundAirports = [];

  constructor(@Inject(DOCUMENT) private document: Document,
              private apiService: ApiService,
              private _vault: StorageMap,
              private _state: StateService,
              public errors: FieldErrorService) {

  }

  openPanel(): void {
    const self = this;
    setTimeout(function() {
      self.inputAutoComplete.open();
    }, 100);
  }

  getIcon(types: string[] = []) {
    if (types.includes('airport')) {
      return faPlaneDeparture;
    } else {
      return faMapMarker;
    }
  }

  geoCodeAddress(prediction: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.geocodeService.geocode({placeId: prediction.place_id}, (results, status) => {
        if (status === 'OK') {
          const data = results[0];
          // console.log(`getAddress() - prediction`, prediction);
          // console.log(`getAddress() - data`, data);
          const address: any = {};
          address.gps = {};
          address.gps.lat = data.geometry.location.lat();
          address.gps.lng = data.geometry.location.lng();
          address.internationalAlias = prediction.description;

          if (data.address_components) {
            data.address_components.forEach((component) => {
              if (component.types[0] === 'postal_code' || component.types[0] === 'postal_code_prefix') {
                address.postalCode = component.short_name.replace(' ', '');
              }
              if (component.types[0] === 'locality') {
                address.city = component.short_name;
              }
              if (component.types[0] === 'country') {
                address.countryCode = component.short_name;
              }
              if (component.types[0] === 'route') {
                address.streetName = component.short_name;
              }
              if (component.types[0] === 'street_number') {
                address.houseNumber = component.short_name.replace(' ', '');
              }
            });
          }
          return resolve(address);
        } else {
          return reject('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  getAddress(prediction: any) {
    this.hasLocationActive = true;
    // console.log(`getAddress() - prediction`, prediction);
    if (typeof prediction === 'string' || !prediction) {
      this.hasErrors = true;
      this.hasLocationActive = false;
      this.address = null;
      const patch = {};
      patch[`${this.config.property}Model`] = null;
      this.form.patchValue(patch);
      this.checkFieldValidity();
      return null;
    }

    if(prediction.type === 'postcode' && !prediction.housenumber) {
      this.search(prediction.formatted_address)
      this.openPanel();
      return null;
    }

    const address: any = {
      type: prediction.type || 'generic',
      code: prediction.code,
      gps: {}
    };

    if (prediction.types && prediction.types.includes('airport') && (prediction.structured_formatting && prediction.structured_formatting.main_text)) {
      address.type = 'airport';
      address.synonym = prediction.structured_formatting.main_text;
    } else if (prediction.types && prediction.types.includes('point_of_interest') && prediction.structured_formatting.main_text) {
      address.type = 'poi';
      address.synonym = prediction.structured_formatting.main_text;
    } else if (prediction.types && prediction.types.includes('address')) {
      address.synonym = prediction.formatted_address;
    }
    if (prediction.type === 'postcode') {
      address.gps.lat = prediction.gps.lat;
      address.gps.lng = prediction.gps.lng;
      address.internationalAlias = prediction.formatted_address;
      address.postalCode = prediction.postCode;
      address.streetName = prediction.streetName;
      address.city = prediction.city;
      address.countryCode = prediction.countryCode;
      this.address = address;
      this.storeAddress();
    } else if (!prediction.custom) {
      this.geoCodeAddress(prediction).then(result => {
        Object.keys(result).forEach((key) => {
          address[key] = result[key];
        });
        this.address = address;
        this.storeAddress();
      });
    } else {
      address.gps.lat = prediction.gps.lat;
      address.gps.lng = prediction.gps.lng;
      address.internationalAlias = prediction.formatted_address;
      if (prediction.housenumber) {
        address.houseNumber = prediction.housenumber;
      }
      this.address = address;
      this.storeAddress();
    }
  }

  storeAddress() {
    const patch = {};
    if(this.config.formArray) {
      patch['stopOvers'] = [];
      patch['stopOversModel'] = [];
      patch['stopOvers'][this.config.property] = this.address.internationalAlias;
      patch['stopOversModel'][this.config.property] = this.address;
    } else {
      patch[`${this.config.property}Model`] = this.address;
      patch[this.config.property] = this.address.internationalAlias;
    }

    this.form.patchValue(patch);
    this.checkWorkArea.emit(this.config.property);
    this.checkFieldValidity();
  }

  checkFieldValidity(): void {
    if(!this.address) {
      return;
    }
    // console.log(`[AddressFieldComponent].checkFieldValidity for ${this.config.property}`);
    if (this.config.houseNumberRequired && !this.address.houseNumber && this.address.type === 'generic') {
      this.errors.addError({
        id: `${this.config.property}HouseNumber`,
        property: this.config.property,
        text: 'address_field_error_house_number',
        label: this.config.label,
      });
    } else {
      this.errors.removeError(`${this.config.property}HouseNumber`);
    }
    if(this.config.formArray) {
      // @ts-ignore
      if (this.form.controls['stopOvers'].controls[this.config.property].value) {
        this.errors.removeError(`${this.config.property}Required`);
      }
    } else {
      if (this.form.controls[this.config.property].value) {
        this.errors.removeError(`${this.config.property}Required`);
      }
    }
  }

  searchGoogle(term: string): Promise<any[]> {
    return new Promise((resolve) => {
      if (!term) {
        return resolve([]);
      }

      this.autocompleteService.getPlacePredictions({
        ...this.autocompleteSettings,
        input: term,
        language: this._state.companySettings.autocompleteLanguage,
        sessionToken: this.autocompleteSessionToken
      }, (predictions: any[]) => {
        const results = [];
        if (predictions && predictions.length > 0) {
          predictions.forEach(prediction => {
            prediction.types.push('google');
            results.push(prediction);
          });
        }
        return resolve(results || []);
      });
    });
  }

  convertAirportToPoi(airport: any) {
    return {
      ...airport,
      custom: true,
      description: `${airport.name} (${airport.code})`,
      formatted_address: `${airport.name} (${airport.code})`,
      type: 'airport',
      types: [
        'airport',
        'airportPoi'
      ],
    };
  }

  customFilter(countries: any[], query: string): any[] {
    // return countries.filter(x => x.description.toLowerCase().startsWith(query.toLowerCase()));
    return this.data;
  };

  searchAirports(term: string): Promise<any[]> {
    return new Promise((resolve) => {
      const results = [];
      const escapedTerm = escapeRegExp(term);
      const regex = new RegExp(escapedTerm, 'gi');
      this.airports.forEach(airport => {
        const nameMatch = airport.name.match(regex);
        const codeMatch = airport.code.match(regex);
        if (nameMatch) {
          results.push(this.convertAirportToPoi(airport));
        } else if (codeMatch) {
          results.push(this.convertAirportToPoi(airport));
        }
      });
      this.foundAirports = results;
      return resolve(results);
    })
  }

  searchPostcodes(term: string): Promise<any[]> {
    const self = this;
    return new Promise((resolve) => {
      const results = [];
      term = escapeRegExp(term);
      var pro6pp_auth_key = "B1uhxwCW6FKNPQI1";

      if(!/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(term) && (/^[1-9][0-9]{3}$/i.test(term) || /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{1}$/i.test(term))
      ) {
        return self.apiService.getCustomUrl(`https://api.pro6pp.nl/v2/suggest/nl/postalCode?authKey=${pro6pp_auth_key}&postalCode=${term}&maxResults=5`)
          .then((results) => {
            if (results.error_id) {
              resolve([]);
            }
            let addressResult = [];
            results.forEach((result) => {
              addressResult.push( {
              custom: true,
              description: `${result.postalCode}, ${result.settlement}`,
              formatted_address: `${result.postalCode}, ${result.settlement}`,
              internationalAlias: `${result.postalCode}, ${result.settlement}`,
              type: 'postcode',
              gps: {
                lat: result.lat,
                lng: result.lng
              }});
            });
            resolve(addressResult);
          })
          .catch(error => console.log('error', error));
      } else if(term.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i) ||
        term.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2} $/i) ||
        term.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2} ([0-9]+)$/i)) {
        let matches = term.match(/^([1-9][0-9]{3}(?!sa|sd|ss)[a-z]{2}) ([0-9]+)$/i);
        if(!matches) {
          matches = term.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i);
        }
        if(!matches) {
          matches = term.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2} $/i);
        }
        let streetNumber = (matches[2] ? matches[2] : null);
        const postcode = (matches[1] ? matches[1] : matches[0]);
        return self.apiService.getCustomUrl(`https://api.pro6pp.nl/v2/autocomplete/nl?authKey=${pro6pp_auth_key}&postalCode=${postcode}&streetNumber=${(streetNumber ? streetNumber : '1')}`)
          .then((result) => {
            if (result.error_id) {
              resolve([]);
            }
            let addressResult: any;
            if(!streetNumber) {
              addressResult = [];
              for(let x=1;x<20;x++) {
                addressResult.push({
                  custom: true,
                  description: `${result.postalCode} ${x}, ${result.street}, ${result.settlement}`,
                  formatted_address: `${result.postalCode} ${x}`,
                  internationalAlias: `${result.postalCode} ${x}`,
                  type: 'postcode',
                  gps: {
                    lat: result.lat,
                    lng: result.lng
                  }
                });
              }
            } else {
              addressResult = [{
                custom: true,
                description: `${result.street} ${result.streetNumber}, ${result.postalCode}, ${result.settlement}`,
                formatted_address: `${result.street} ${result.streetNumber}, ${result.postalCode}, ${result.settlement}`,
                internationalAlias: `${result.street} ${result.streetNumber}, ${result.postalCode}, ${result.settlement}`,
                housenumber: `${result.streetNumber}`,
                countryCode: `${result.countryCode}`,
                type: 'postcode',
                gps: {
                  lat: result.lat,
                  lng: result.lng
                }
              }];
            }
            resolve(addressResult);
          })
          .catch(error => console.log('error', error));
      }
      return resolve(results);
    });
  }

  getLocation(): void {
    const self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function geoSuccess(position) {
        self.currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        self.hasLocationActive = true;
      }, function error(error) {
      });
    }
  }

  resolveCurrentAddress(): Promise<any> {
    const self = this;
    return new Promise((resolve) => {
      self._vault.get(JSON.stringify(self.currentLocation)).subscribe((cachedResult) => {
        if (cachedResult) {
          return resolve(cachedResult);
        }

        self.apiService.getCustomUrl('https://api.geoapify.com/v1/geocode/reverse?lat=' + self.currentLocation.lat + '&lon=' + self.currentLocation.lng + '&apiKey=daafb5046fe84b3fbbce4a00375d2745')
          .then((result) => {
            let addressResult = {
              custom: true,
              description: result.features[0].properties.formatted,
              formatted_address: result.features[0].properties.formatted,
              internationalAlias: result.features[0].properties.formatted,
              housenumber: result.features[0].properties.housenumber,
              type: 'generic',
              gps: {
                lat: result.features[0].properties.lat,
                lng: result.features[0].properties.lon
              }
            };
            self._vault.set(JSON.stringify(self.currentLocation), addressResult);
            resolve(addressResult);
          })
          .catch(error => console.log('error', error));
      })
    });
  }

  search(term: string) {
    this.loading = true;
    this.hasLocationActive = false;
    this.data = [];
    let airports = [];
    let googleResults = [];
    this.loading = false;
    return this.searchAirports(term)
      .then(results => {
        if (results) {
          airports = results;
          this.data = this.data.concat(airports);
        }
        return this.searchPostcodes(term);
      }).then(results => {
        if (results && results.length > 0) {
          this.data = this.data.concat(results);
          this.loading = false;
        } else {
          this.searchGoogle(term)
            .then((results) => {
              if (results) {
                this.data = this.data.concat(this.filterGoogleResults(results));
                this.loading = false;
              }
            })
        }
      });
  }

  selectFirstOption($event): void {
    if (!this.hasLocationActive) {
      this.getAddress(this.data[0]);
    }
  }

  setCurrentLocation(): void {
    this.resolveCurrentAddress()
      .then((result) => {
        this.getAddress(result);
      })
  }

  clear(): void {
    const patch = {};
    if(this.config.formArray) {
      patch['stopOvers'] = [];
      patch['stopOversModel'] = [];
      patch['stopOvers'][this.config.property] = '';
      patch['stopOversModel'][this.config.property] = null;
    } else {
      patch[this.config.property] = '';
      patch[`${this.config.property}Model`] = null;
    }
    this.form.patchValue(patch);
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

  ngOnInit() {
    const _self = this;

    if (this.config.allowBrowserLocation) {
      if (navigator.geolocation) {
        this.getLocation();
      }
    }

    if (this.config.googleComponentRestrictions) {
      // @ts-ignore
      this.autocompleteSettings.componentRestrictions.country = this.config.googleComponentRestrictions.map((c) => {
        return (c === 'EN' ? 'GB' : c);
      });
    }
    this.airports = [];
    Object.keys(AIRPORTS).forEach((key) => {
      if (this.autocompleteSettings.componentRestrictions['country'] && this.autocompleteSettings.componentRestrictions['country'].includes(key.toUpperCase())) {
        this.airports = this.airports.concat(AIRPORTS[key]);
      }
    });
  }

  ngAfterViewInit(): void {
    const _self = this;
    const input = document.querySelector(`#autoComplete-${this.config.property} input[type=text]`);
    if (input) {
      input.setAttribute('type', 'search');
      input.setAttribute('autocomplete', `${this.config.property}-address`);
    }

    if (typeof (google) === 'undefined' || typeof (google.maps) === 'undefined') {
      setTimeout(function () {
        _self.ngAfterViewInit();
        // console.log('Lazy loading google');
      }, 150);
    } else {
      try {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
        this.geocodeService = new google.maps.Geocoder();
        this.results = concat(
          of([]), // default items
          this.input.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.loading = true),
            switchMap(term => this.searchGoogle(term)),
            tap(() => this.loading = false)
          )
        );

        if (this.form.value[`${this.config.property}Model`] && this.config.property === 'destination') {
            _self.checkWorkArea.emit(_self.config.property);
        }
        this.address = this.form.value[`${this.config.property}Model`];
      } catch (e) {
        setTimeout(function () {
          _self.ngAfterViewInit();
          // console.log('Lazy loading google');
        }, 150);
      }
    }
  }

  toggleHelpModal(helpTitleTag: string, helpTextTag: string, type?: string, meta?: any) {
    this.openHelpModal.emit({
      helpTitleTag,
      helpTextTag,
      type,
      meta,
    });
  }

  private filterGoogleResults(results: any[]) {
    /**
     * Filter all results that contain an airport code in the description string that we have in the AIRPORTS
     */
    results = results.filter((result) => {
      if (result.description) {
        let found = false;
        this.foundAirports.forEach(airport => {
          found = result.description.indexOf(`\(${airport.code}\)`) > -1;
        });
        return !found;
      }
    });
    return results;
  }
}

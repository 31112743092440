import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {Field} from "../../interfaces/field";
import {AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormControl} from "@angular/forms";
import {StateService} from "../../services/state.service";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FieldErrorService} from "../../services/field-error.service";
import {AddressFieldComponent} from "../address-field/address-field.component";

@Component({
  selector: 'app-sortable-address-field',
  templateUrl: './sortable-address-field.component.html',
  styleUrls: ['./sortable-address-field.component.scss']
})
export class SortableAddressFieldComponent implements OnInit, OnDestroy{
  @Input() departureConfig: Field;
  @Input() destinationConfig: Field;
  @Input() form: FormGroup;
  _state: StateService
  @Output() openHelpModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkWorkArea: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren(AddressFieldComponent) addressFields: AddressFieldComponent[];
  protected addressArray = [];
  protected stopOverConfig;


  private drag: any;
  protected yPos: number;
  private xPos: number;
  protected yOrigin: number;
  private xOrigin: number;
  private change: number;
  private rowHeight = 75;

  constructor(private state: StateService,
              public errors: FieldErrorService) {
    this._state = state;
  }

  convertToFormControl(absCtrl: AbstractControl | null): UntypedFormArray {
    const ctrl = absCtrl as FormArray;
    return ctrl;
  }

  ngOnInit() {
    this.setupAddressArray();
  }

  addStopOver() {
    // @ts-ignore
    this.form.controls['stopOvers'].controls.push(new UntypedFormControl([]));
    // @ts-ignore
    this.form.controls['stopOversModel'].controls.push(new UntypedFormControl([]));
    this.setupAddressArray();
  }

  removeDestination(i: number) {
    // @ts-ignore
    this.form.controls['stopOvers'].controls.splice(i, 1);
    this.form.controls['stopOvers'].updateValueAndValidity();

    // @ts-ignore
    this.form.controls['stopOversModel'].controls.splice(i, 1);
    this.form.controls['stopOversModel'].updateValueAndValidity();
    this.addressArray.splice((i + 1), 1);
  }

  ngOnDestroy(): void {
  }

  toggleHelpModal(helpTitleTag: string, helpTextTag: string, type?: string, meta?: any) {
    this.openHelpModal.emit({
      helpTitleTag,
      helpTextTag,
      type,
      meta,
    });
  }

  checkWorkAreaEmit(property) {
    this.checkWorkArea.emit(property);
  }

  protected readonly faInfoCircle = faInfoCircle;

  hasStopOvers() {
    // @ts-ignore
    return (this.addressArray && this.addressArray.length > 0)
  }

  setupAddressArray() {
    this.addressArray = [];
    this.addressArray.push({
      control:this.form.controls['departure'],
      controlModel:this.form.controls['departureModel'],
      config: this.departureConfig
    });
    // @ts-ignore
    if (this.form.controls['stopOvers'].controls.length > 0) {
      // @ts-ignore
      this.form.controls['stopOvers'].controls.forEach((control, i) => {
        this.stopOverConfig = JSON.parse(JSON.stringify(this.destinationConfig));
        this.stopOverConfig.formArray = 'stopOvers';
        this.stopOverConfig.label = 'stopover';
        this.stopOverConfig.property = i;

        this.addressArray.push({
          control:control,
          // @ts-ignore
          controlModel:this.form.controls['stopOversModel'].controls[i],
          config: this.stopOverConfig
        });
      });
    }

    this.addressArray.push({
      control:this.form.controls['destination'],
      controlModel:this.form.controls['destinationModel'],
      config: this.destinationConfig
    });
  }

  getPosY = (id: string) =>  {
    return (this.drag && id === this.drag.id ? this.yPos : 0);
  }

  getPosX = (id: string) =>  {
    return (this.drag && id === this.drag.id ? this.xPos : 0);
  }

  drop = (event: any, item: any) => {
    this.change = Math.round(this.yPos / this.rowHeight);
    if (this.change && this.change !== 0) {
      this.updatePrio(this.change, event);
      this.sort();
    }

    this.drag = null;
    this.yPos = 0;
    this.xPos = 0;
    document.querySelector('body').classList.remove('lock-screen');
  }

  /**
   * When mouse is pressed, define the origin from which to calculate
   * the change in direction for the element to be dragged
   */
  getEventCoordinates (e): any[] {
    let x,y;

    if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
      let touch = e.touches[0] || e.changedTouches[0];
      x = touch.clientX;
      y = touch.clientY;
    } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover' || e.type == 'mouseout' || e.type == 'mouseenter' || e.type == 'mouseleave') {
      x = e.x;
      y = e.y;
    }
    return [x,y];
  }

  pick(event: any, item: any, index) {
    if(this.addressArray && this.addressArray.length <= 2) {
      return;
    }

    const coor = this.getEventCoordinates(event);
    this.yOrigin = coor[1];
    this.xOrigin = coor[0];
    this.drag = {
      id: item,
      index: index,
    };
  }
  /**
   * When the mouse moves, and while drag._id has a value, calculate
   * the change of position relative to the origin
   */
  move = (event: any, item: any) => {
    if(this.addressArray && this.addressArray.length <= 2) {
      return;
    }

    const coor = this.getEventCoordinates(event);
    if (this.drag) {
      this.yPos = coor[1] - this.yOrigin;
      this.xPos = coor[0] - this.xOrigin;
      if(!document.querySelector('body').classList.contains('lock-screen')) {
        document.querySelector('body').classList.add('lock-screen');
      }
    } else {
      this.yPos = 0;
      this.xPos = 0;
    }
    this.change = Math.round(this.yPos / (this.rowHeight + 30));
    if(this.change !== 0) {
      this.updatePrio(this.change, event);
      this.sort();
    }
  }

  sort() {
    /**
     * Set first element to departure
     */
    const dataArray = [];
    this.addressArray.forEach((control, i) => {
      dataArray.push(JSON.parse(JSON.stringify({
        control: control.control.value,
        controlModel: control.controlModel.value,
      })));
    });

    dataArray.forEach((data, i) => {
      delete data.index;
      if (i === 0) {
        this.form.controls['departure'].setValue(data.control);
        this.form.controls['departureModel'].setValue(data.controlModel);
        // this.departure = data;
      } else if (!this.addressArray[(i + 1)]) {
        // this.destination = data;
        this.form.controls['destination'].setValue(data.control);
        this.form.controls['destinationModel'].setValue(data.controlModel);
      } else
        // @ts-ignore
      if (this.form.controls['stopOvers'].controls.length > 0 && this.form.controls['stopOvers'].controls[(i - 1)]) {
        // @ts-ignore
        this.form.controls['stopOvers'].controls[(i - 1)].setValue(data.control);
        // @ts-ignore
        this.form.controls['stopOversModel'].controls[(i - 1)].setValue(data.controlModel);
      }
    });
    this.addressArray = [];
    this.setupAddressArray();
    console.group('Address Sort')
    console.log(this.form.controls['departureModel'].value);
    console.log(this.form.controls['stopOversModel'].value);
    console.log(this.form.controls['destinationModel'].value);
    console.groupEnd();
    this.form.controls['stopOvers'].updateValueAndValidity();
  }

  updatePrio(amount: number, event) {
    const coor = this.getEventCoordinates(event);

    if (!this.drag) {
      return;
    }
    // @ts-ignore
    const totalAddresses = this.form.controls['stopOvers'].controls.length + 2;
    if (amount) {
      if (amount > totalAddresses) {
        amount = totalAddresses;
      } else if (amount < (0 - totalAddresses)) {
        amount = (0 - totalAddresses);
      }
      const switchItem = this.drag.index + amount;
      if (this.addressArray[switchItem]) {
        const tmp =
          this.addressArray[switchItem];
        this.addressArray[switchItem] = this.addressArray[this.drag.index];
        this.addressArray[this.drag.index] = tmp;
        this.yPos = 0;
        this.xPos = 0;
        this.yOrigin = coor[1];
        this.xOrigin = coor[0];
        this.drag = null;
      }
      document.querySelector('body').classList.remove('lock-screen');
    }
  }

  getAddressArray() {
    return this.addressArray;
  }

  checkFieldValidity() {
    this.addressFields.forEach((address: AddressFieldComponent) => {
      address.checkFieldValidity();
    });
  }

  addErrors(error) {
    this.addressFields.forEach((address: AddressFieldComponent) => {
      if(address.config.property === error.property) {
        address.errors.addError(error);
      }
    });
  }

  removeErrors(error) {
    this.addressFields.forEach((address: AddressFieldComponent) => {
      if(address.config.property === error.property) {
        address.errors.removeError(error.id);
      }
    });
  }
}

<ng-container *ngFor="let part of parts; index as i;">
  <div class="wb-chapter" *ngIf="i < selectedFormIndex && part.sidebarSummary && (part.type !== 'products' ||
  (part.type === 'products' && formGroup.controls.returnTrip?.value))">
    <h4>{{(parts[i]?.summaryTitle || parts[i]?.title) | translate | ucFirst}}</h4>
    <div class="wb-field" *ngFor="let field of part.fields;">
      <div class="wb-name" *ngIf="field.type !== 'collapse' && formGroup.controls[field.property]?.value !== 0 &&
             formGroup.controls[field.property]?.value !== ''">
        {{(field.label ? field.label : field.placeholder) | translate | ucFirst}}
      </div>
      <div *ngIf="field.type !== 'collapse' && formGroup.controls[field.property]?.value !== 0 &&
             formGroup.controls[field.property]?.value !== ''">
        <app-field-value [field]="field" [form]="form" [formGroup]="formGroup"></app-field-value>
      </div>

      <div class="wb-field" *ngFor="let field of field.fields;">
        <div class="wb-name"
             *ngIf="field.type !== 'collapse' && formGroup.controls[field.property]?.value !== 0 &&
             formGroup.controls[field.property]?.value !== ''">{{field.label |
          translate | ucFirst}}</div>
        <app-field-value [field]="field" [form]="form" [formGroup]="formGroup"></app-field-value>
      </div>
    </div>
  </div>
</ng-container>
